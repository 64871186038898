<template>
  <div>
    <v-card class="rounded-lg">
      <v-list>
        <v-subheader>Medios de Pago</v-subheader>
        <div v-for="(card, i) in infoCardList" :key="i">
          <dataListInfoComponent :data="card" />
        </div>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import dataListInfoComponent from "@/components/inicioView/dataListInfoComponent.vue";
export default {
  name: "cardListInfoComponent",
  components: {
    dataListInfoComponent,
  },

  props: {
    infoCardList: {
      type: Array,
      default: [],
    },
  },
  mounted() {
    console.log("el valor de array", this.infoCardList);
  },
};
</script>

<style lang="scss" scoped></style>
