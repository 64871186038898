<template>
  <div>
    <v-card elevation="2" class="rounded-lg">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-subtitle>{{ subTitle + " 😎" }}</v-card-subtitle>
      <v-row class="ma-2">
        <v-col cols="12" md="4" v-for="(card, i) in datosCard" :key="i">
          <cardDataInfoComponent :infoCard="card" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import cardDataInfoComponent from "@/components/inicioView/cardDataInfoComponent.vue";
export default {
  name: "dataInfoCardxComponent",
  components: {
    cardDataInfoComponent,
  },

  props: {
    title: {
      type: String,
      default: "Sin Datos",
    },
    subTitle: {
      type: String,
      default: "Sin Datos",
    },
    datosCard: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped></style>
