const formattedValue = (valorPrecio)=> {
    // Convertimos el string a número
    const numericValue = parseFloat(valorPrecio);

    // Verificamos si el valor es un número válido
    if (!isNaN(numericValue)) {
      // Opciones para personalizar el formato
      const options = {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true, // Habilita el uso de separadores de miles (puntos o comas)
      };

      // Formateamos el número con separadores de miles (comas) usando toLocaleString()
      return numericValue.toLocaleString("es-ES", options);
    } else {
      return "Valor no válido";
    }
  }

  export default formattedValue